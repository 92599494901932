import logo from './logo.svg';
import './App.css';
import { useState, useRef } from 'react';
import { Configuration, OpenAIApi } from "openai"
import { Audio, Dna } from 'react-loader-spinner'

function App() {
  const [emojis, setEmojis] = useState('')
  const [loading, setLoading] = useState(false)
  const word = useRef()

  async function handleEmojify() {
    setLoading(true);
    const emoji_sentence = await getEmojis()
    setLoading(false);
    console.log("fulfilled!")
    const emoji_icons = emoji_sentence.match(/<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu)
    const gender = ['♂','♀']
    const icons = emoji_icons.filter((e)=> !gender.includes(e)).join('')
    setEmojis(icons)
  }

  const openAi = new OpenAIApi(
    new Configuration({
      apiKey: "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",
    })
  )
  
  async function getEmojis() {
    const response = await openAi.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [
        {"role": "system", "content": "You are a helpful assistant."},
        {"role": "user", "content": `Give me 4 emojis associated with ${word.current.value}`}
      ]
    })
    return response.data.choices[0].message.content
  }

  return (
    <>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    <h1>
      <img src="./emojify.png"></img>
    </h1>
    <p>
      <input id="bar_input" ref={word} type="text"/>
      <input class="btn" type="image" id="go_button" onClick={handleEmojify} src="./go.png"></input>
      <input class="btn" type="image" id="go_2_button" onClick={handleEmojify} src="./go_2.png"></input>
      <div class="container">
        {
          loading ?
            <Audio
              visible={true}
              height="80"
              width="80"
              color="#2A2F4F"
              ariaLabel="audio-loading"
              wrapperStyle={{}}
              wrapperClass="audio-wrapper"
            />
          : 
          <>
          <div class="copy-text">
            <p>
              <emj>{emojis}</emj> 
              <div>
                {emojis ? <>
                  <input class="btn copy" type="image" id="copy_button" onClick={() => navigator.clipboard.writeText(emojis)} src="./copy.png"></input>
                  <input class="btn copy" type="image" id="copy_2_button" onClick={() => navigator.clipboard.writeText(emojis)} src="./copy_2.png"></input>
                </>
                : <></>}
              </div>
            </p>
          </div>
          </>
        }
      </div>
      <cr>© 2023 Arian | All Rights Reserved</cr>
    </p>
    </>
  );
}

export default App;
